import React, { FC } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
type LightBulbProps = {}

const LightBulb: FC<LightBulbProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} fill="none">
      <circle cx={18} cy={18} r={18} fill="#00A673" />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M26.806 14.903c0-5.638-5.24-10.05-11.115-8.637-3.266.787-5.731 3.31-6.462 6.59-.805 3.608.584 6.949 3.087 8.967 1.127.91 1.816 2.252 1.816 3.7 0 .849.689 1.538 1.538 1.538h4.465c.85 0 1.538-.689 1.538-1.539v-.001c0-1.41.624-2.76 1.733-3.63a8.87 8.87 0 0 0 3.4-6.988Z"
        clipRule="evenodd"
      />
      <path
        fill="#75FABF"
        fillRule="evenodd"
        d="M17.7 8.743c-.105 0-.207.01-.31.016.038.153.065.31.065.475a1.951 1.951 0 0 1-1.952 1.951 1.942 1.942 0 0 1-1.69-.993 5.936 5.936 0 0 0-2.07 4.508A5.957 5.957 0 1 0 17.7 8.743Z"
        clipRule="evenodd"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.157 29.985h-2.135a1.165 1.165 0 0 1-1.165-1.165v-1.563h4.465v1.563c0 .643-.522 1.165-1.165 1.165Z"
        clipRule="evenodd"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.857 9.44c.01-.004.019-.007.028-.012M12.429 12.8a5.9 5.9 0 0 1 1.184-2"
      />
    </svg>
  )
}

export default LightBulb
