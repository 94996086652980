import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import { useAuth0 } from '@auth0/auth0-react'

import { AuthenticationGuard } from './auth/authentication-guard'
import {
  Login,
  Logout,
  Payment,
  RouteToPayment,
  RouteNotFound
} from './views'
import './App.scss'
import ErrorBoundary from "./errorBoundary";

export const App = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()  
  
  useEffect(() => {
    if (!isAuthenticated) { return }

    (async () => {
      try {
        const authToken = await getAccessTokenSilently()
        const decodedToken = jwtDecode(authToken)
        const mpId = decodedToken[
          'https://my.akili.care/app_metadata'
        ]?.alias?.mp
        
        if (mpId && global?.mixpanel?.identify) {
          global?.mixpanel?.identify(mpId)
        }
      } catch (e) {
        console.error('fetch token err', e)
      }
    })()
  }, [isAuthenticated, getAccessTokenSilently])

  return (
    <ErrorBoundary>
      <Routes>
        <Route
          path="/login"
          element={<Login />}
        />
        <Route
          path="/logout"
          element={<Logout />}
        />
        <Route
          path="/checkout"
          element={<RouteToPayment />}
        />
        <Route
          path="/payment"
          element={<AuthenticationGuard component={Payment} />}
        />
        <Route
          path="/route-not-found"
          element={<RouteNotFound />}
        />
        <Route path="*" element={<RouteNotFound />} />
      </Routes>
    </ErrorBoundary>
  )
}

