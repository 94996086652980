import React, { FC } from 'react'

type CloseProps = {
  fill?: string
  width?: number
  height?: number
}

const Close: FC<CloseProps> = ({
  width = 16,
  height = 16,
  fill = 'black'
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <defs>
        <path
          d="M18.295,5.705 C18.6843607,6.09436075 18.6843607,6.72563925 18.295,7.115 L13.41,12 L18.295,16.885 C18.6843607,17.2743607 18.6843607,17.9056393 18.295,18.295 C17.9056393,18.6843607 17.2743607,18.6843607 16.885,18.295 L12,13.41 L7.115,18.295 C6.72563925,18.6843607 6.09436075,18.6843607 5.705,18.295 C5.31563925,17.9056393 5.31563925,17.2743607 5.705,16.885 L10.59,12 L5.705,7.115 C5.31563925,6.72563925 5.31563925,6.09436075 5.705,5.705 C6.09436075,5.31563925 6.72563925,5.31563925 7.115,5.705 L12,10.59 L16.885,5.705 C17.2743607,5.31563925 17.9056393,5.31563925 18.295,5.705 Z"
          id="path-close-icon"
        />
      </defs>
      <g id="g_icons_close" fill="none" fillRule="evenodd">
        <use
          id="u_icon_close"
          fill={fill}
          fillRule="nonzero"
          href="#path-close-icon"
          xlinkHref="#path-close-icon"
        />
      </g>
    </svg>
  )
}

export default Close
