import React, { FC } from 'react'
import model from './model.en.json'
import styles from './styles.module.scss'

type FooterProps = {
  content?: string
  placeholderURLs?: boolean
  context?: string
  theme?: string
  overlayContent?: boolean
  brandVersion?: string
}

const termsOfUseUrl = process.env.REACT_APP_AKILI_TOU_LINK
const privacyNoticeUrl = process.env.REACT_APP_AKILI_PP_LINK

const Footer: FC<FooterProps> = ({
  content,
  placeholderURLs = false,
  context,
  theme = 'dark',
  overlayContent = false,
  brandVersion = 'v2'
}) => {
  const id = context || 'footer'
  const brandStyleName = `brand_${brandVersion}`

  const defaultContent = model.defaultContentV2

  const privacyNoticeUrlToUse = placeholderURLs
    ? 'https://pn.com'
    : privacyNoticeUrl
  const termsOfUseUrlToUse = placeholderURLs ? 'https://tou.com' : termsOfUseUrl
  return (
    <div
      id={`${id}-container`}
      className={`${styles.footer} ${
        overlayContent ? styles.overlayContent : ''
      } ${theme === 'dark' ? '' : styles.light}`}
    >
      <div className={styles.content}>
        <span data-aid="Footer" id={`${id}-description`}>
          {content || defaultContent}
        </span>
        &nbsp;
        <a
          data-aid="TermsLink"
          id={`${id}-tou-link`}
          href={termsOfUseUrlToUse}
          target="_blank"
          rel="noreferrer"
          className={`${styles[brandStyleName]}`}
        >
          {model.termsOfUse}
        </a>
        <span data-aid="Separator1" id={`${id}-divider`}>
          {' '}
          |{' '}
        </span>
        &nbsp;
        <a
          data-aid="PrivacyLink"
          id={`${id}-pn-link`}
          href={privacyNoticeUrlToUse}
          target="_blank"
          rel="noreferrer"
          className={`${styles[brandStyleName]}`}
        >
          {model.privacyNotice}
        </a>
      </div>
    </div>
  )
}

export default Footer
