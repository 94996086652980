import React, { FC } from 'react'
import variables from '../../../styles/variables/index.scss'

type CircleExclamationProps = {
  fill?: string
}

const CircleExclamation: FC<CircleExclamationProps> = ({ fill = variables.colorBannerText }) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zM8 4a2 2 0 114 0v6a2 2 0 11-4 0V4zm4 12a2 2 0 11-4 0 2 2 0 014 0z"
        fill={fill}
      />
    </svg>
  )
}

export default CircleExclamation
