import ObjectPropertiesToCamelCase from '../object-parse-methods'
import APIService from './api-service'

export const createAkiliApi = (
  authToken: string | null = null,
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH',
  name: string,
  payload?: Record<string, unknown> | null | undefined,
  normalizeData?: (obj: any) => any
): Promise<any> => {
  const BaseAPI = new APIService(
    process.env.REACT_APP_API_URL + process.env.REACT_APP_API_VERSION,
    authToken
  )

  switch (method) {
    case 'GET':
      return BaseAPI.get(url, name).then((res) => {
        const formattedData = ObjectPropertiesToCamelCase(res)
        if (normalizeData) {
          return normalizeData(formattedData)
        }
        return formattedData
      })
    case 'POST':
      return BaseAPI.post(url, name, payload || {}).then((res) => {
        const formattedData = ObjectPropertiesToCamelCase(res)
        if (normalizeData) {
          return normalizeData(formattedData)
        }
        return formattedData
      })
    case 'PUT':
      return BaseAPI.put(url, name, payload || {}).then((res) => {
        const formattedData = ObjectPropertiesToCamelCase(res)
        if (normalizeData) {
          return normalizeData(formattedData)
        }
        return formattedData
      })
    case 'PATCH':
      return BaseAPI.patch(url, name, payload || {}).then((res) => {
        const formattedData = ObjectPropertiesToCamelCase(res)
        if (normalizeData) {
          return normalizeData(formattedData)
        }
        return formattedData
      })
    default:
      return new Promise<void>((resolve) => {
        resolve()
      })
  }
}

export const createDispensaryApi = (
  authToken: string | null = null,
  url: string,
  name: string,
  payload?: Record<string, unknown> | null | undefined,
  normalizeData?: (obj: any) => any
): Promise<any> => {
  const BaseAPI = new APIService(
    process.env.REACT_APP_DISPENSARY_URL,
    authToken
  )

  return BaseAPI.post(url, name, payload || {}).then((res) => {
    let formattedData
    if (normalizeData) {
      formattedData = ObjectPropertiesToCamelCase(res)
      return normalizeData(formattedData)
    }

    formattedData = ObjectPropertiesToCamelCase(res)
    return ObjectPropertiesToCamelCase(res)
  })
}
