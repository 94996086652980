import { jwtDecode } from 'jwt-decode'

declare let mixpanel: any

interface IIndexable {
  [key: string]: any
}
class Analytics {
  superProperty = (name: string, value: unknown) => {
    const property = {
      [name]: value
    }
    mixpanel.register(property)
  }

  identify = (authToken: string, partner: 'MIXPANEL') => {
    const decodedToken = jwtDecode(authToken)
    if (partner === 'MIXPANEL') {
      const uuid = (decodedToken as IIndexable)[
        'https://my.akili.care/app_metadata'
      ]?.alias?.mp
      if (uuid) {
        mixpanel.identify(uuid)
      }
    }
  }

  recordEvent = (
    name: string,
    authToken: string | null,
    partner: 'MIXPANEL',
    segmentation: unknown = null
  ) => {
    if (partner === 'MIXPANEL') {
      if (authToken) this.identify(authToken, 'MIXPANEL')
      mixpanel.track(name, segmentation)
    }
  }
}

const analyticsInstance = new Analytics()
export default analyticsInstance
