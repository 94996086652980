import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import QueryString from 'query-string'

const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate()

  const domain = process.env.REACT_APP_AUTH0_DOMAIN
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  const initialURL = QueryString.pick(window.location.href, ['price_id', 'coupon_code', 'app_name_id', 'subscription_options', 'welcome', 'instructions'])
    const queryParams = initialURL.split('?')[1]
    const redirectUri = `${window.location.origin}/payment${
      queryParams ? `?${queryParams}` : ''
    }`
    
  if (!(domain && clientId && redirectUri && audience)) {
    return null
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
        initial_screen: 'signup',
        app_name_id: 'T01A',
        social: '1'
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithNavigate
