import React, { FC, useEffect } from 'react'
import {useSearchParams, useNavigate } from 'react-router-dom'
import { Layout, Loading } from '@components'

const RouteToPayment: FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    navigate(`/payment${searchParams && '?' + searchParams}`)
  }, [])

  return (
    <Layout hasFooter>
      <Loading />
    </Layout>
  )
}

export default RouteToPayment
