import { createAkiliApi } from '../../utils/api/api-methods'

type Data = {
  price_id: string
  coupon_code?: string
  email: string
  alias_uuid: string
}

type RequestData = {
  price_id: string
  coupon_code?: string
  email: string
  alias_uuid: string
}

/*
  400	- bad request errors / request validation error
  401	- unauthorized
  500	-  Unexpected Error
  503	-  Unexpected AWS Error
*/

type ErrorResponse = {
  success: false
  status: 400 | 401 | 500 | 503
  localizedErrorMessage: string
  requestParams: RequestData
  responsePayload: {
    message: string
    description: string
  }
}

type SuccessResponse = {
  success: true
  status: 200
  requestParams: RequestData
  responsePayload: {
    paymentSessionId?: string
    errorMessage?: string
  }
}

const url = 'checkout/create'
const name = 'createCheckout'

const createCheckoutAPI = (
  authToken: string,
  data: Data
): Promise<ErrorResponse | SuccessResponse> => {
  const payload = {
    ...data
  } as RequestData

  return createAkiliApi(authToken, url, 'POST', name, payload)
}

export default createCheckoutAPI
