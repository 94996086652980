import React, { FC, Fragment } from 'react'
import styles from './styles.module.scss'
import Iconography from '../Iconography'

type InstructionBannerProps = {
  message?: string
  context?: string
  hasCloseIcon?: boolean
  automationId?: string
  handleOnCloseClick?: () => void
}

const InstructionBanner: FC<InstructionBannerProps> = ({
  message,
  context,
  hasCloseIcon = true,
  handleOnCloseClick = () => {},
  automationId
}) => {
  if (!message) return null
  const id = context || 'instructionBanner'

  const renderCloseButton = () => {
    if (!hasCloseIcon) {
      return null
    }

    return (
        <div
          className={styles.contentCloseIcon}
          onClick={handleOnCloseClick}
        >
          <Iconography
            name="Close"
            extraClassName={styles.bannerIcon}
            fill="black"
          />
        </div>
    )
  }
  const markup = { __html: `<div>${message}</div>` };
  return (
    <div className={styles.layoutBlend}>
      <div id={id} className={styles.container}>
        {renderCloseButton()}
        <div className={styles.content}>
          <div className={styles.iconContainer}>
            <Iconography name="circleExclamation" />
          </div>
          <div
            data-aid={automationId || 'BlackBoxMessage'}
            id={`${id}-label`}
            className={styles.messageContainer}
          >
            <div dangerouslySetInnerHTML={markup} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstructionBanner
