import React, { FC, useEffect, useState, useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import { loadStripe } from '@stripe/stripe-js'
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js'
import DOMPurify from 'dompurify'
import CryptoJS from 'crypto-js'

import { createCheckoutAPI } from '@apiRoutes'
import { useAuthToken } from '@utils'
import { Layout, Loading, PrimaryButton, InstructionBanner } from '@components'
import { ReactComponent as LogoT01A } from './assets/logo_t01a.svg'
import model from './model.en.json'
import styles from './index.module.scss'
import { UUID } from 'crypto'

interface IIndexable {
  [key: string]: any
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_APP_KEY as string)

const ERR_TYPES = {
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
  SYSTEM_AVAILABILITY_ERROR: 'SYSTEM_AVAILABILITY_ERROR',
  GENERAL_ERROR: 'GENERAL_ERROR',
  SUBSCRIPTION_VALIDATION_ERROR: 'SUBSCRIPTION_VALIDATION_ERROR',
  SUBSCRIPTION_EXISTS_ERROR: 'SUBSCRIPTION_EXISTS_ERROR',
  UNPROCESSABLE_CONTENT: 'UNPROCESSABLE_CONTENT'
} as const

type ErrType = typeof ERR_TYPES[keyof typeof ERR_TYPES] | ''

const ERR_RESPONSE = {
  SUBSCRIPTION_VALIDATION_ERROR: 'Subscription validation error',
  SUBSCRIPTION_EXISTS_ERROR: 'Subscription already exists',
  VALIDATION_ERROR: 'Validation Error',
  UNEXPECTED_ERROR: 'Unexpected Error'
} as const

const Payment: FC = () => {
  const [checkoutId, setCheckoutId] = useState('')

  const [viewLoading, setViewLoading ] = useState(true)
  const [apiRequestInProgress, setApiRequestInProgress ] = useState(false)
  const [viewError, setViewError ] = useState<ErrType>('')
  
  const [priceId, setPrice ] = useState('')
  const [couponCode, setCoupon ] = useState('')
  const [paymentSuccessURL, setPaymentSuccessURL] = useState('')
  const [priceSelectionURL, setPriceSelectionURL] = useState('')
  const [paymentInstructions, setPaymentInstructions] = useState('')
  const [instructionsVisible, setInstructionsVisible] = useState(true)

  const [email, setEmail ] = useState('')
  const [aliasUuid, setAliasUuid ] = useState('')
  
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { authToken, tokenPending } = useAuthToken()
  
  const getAliasUuid = (authToken: string | null) => {
    if (!authToken) return null

    const decodedToken = jwtDecode(authToken)
    const uuid = (decodedToken as IIndexable)[
      'https://my.akili.care/app_metadata'
    ]?.alias?.mp

    return uuid
  }

  const hashAliasUuid = (aliasUuid: UUID) => {
    if (!aliasUuid) { return '' }
    const uuidToHash = aliasUuid?.trim()

    if (uuidToHash) {
      return CryptoJS.SHA256(uuidToHash).toString()
    } else {
      console.log('Failed to hash alias')
    }
  }


  const getEmail = (authToken: string | null) => {
    if (!authToken) return null

    const decodedToken = jwtDecode(authToken)
    const email = (decodedToken as IIndexable)[
      'https://my.akili.care/email'
    ]

    return email
  }

  const createPaymentSession =  useCallback(async () => {
    if (apiRequestInProgress || !priceId || !aliasUuid || !email) { return }
    setViewError('')
    setApiRequestInProgress(true)

    const data = {
      price_id: priceId,
      coupon_code: couponCode,
      email,
      alias_uuid: aliasUuid
    }

    const response = await createCheckoutAPI(
      authToken as string,
      data
    ).catch((err?: Error) => {
      if (err && err.message === 'INVALID_USER') {
        navigate('/logout')
      }
    })

    if (!response) { return }
    if (response.success) {
      const id = response.responsePayload.paymentSessionId || ''
      id && setCheckoutId(id)
      setApiRequestInProgress(false)
    } else {
      const { status, responsePayload: { message } } = response

      if (status === 500 && message.includes(ERR_RESPONSE.UNEXPECTED_ERROR)) {
        setViewError(ERR_TYPES.UNEXPECTED_ERROR)
      } else if (status === 503) {
        setViewError(ERR_TYPES.SYSTEM_AVAILABILITY_ERROR)
      } else if (status === 400 && message.includes(ERR_RESPONSE.VALIDATION_ERROR)) {
        setViewError(ERR_TYPES.VALIDATION_ERROR)
      } else if (status === 400 && message.includes(ERR_RESPONSE.SUBSCRIPTION_VALIDATION_ERROR)) {
        setViewError(ERR_TYPES.SUBSCRIPTION_VALIDATION_ERROR)
      } else if (status === 400 && message.includes(ERR_RESPONSE.SUBSCRIPTION_EXISTS_ERROR)) {
        setViewError(ERR_TYPES.SUBSCRIPTION_EXISTS_ERROR)
      } else {
        setViewError(ERR_TYPES.GENERAL_ERROR)        
      }

      setApiRequestInProgress(false)
    }
  }, [apiRequestInProgress, priceId, aliasUuid, email, couponCode, authToken, navigate])

  const handlePaymentComplete = () => {
    setApiRequestInProgress(true)
    setCheckoutId('')

    paymentSuccessURL && window.open(paymentSuccessURL, '_self')
  }

  const renderRequestError = () => {
    const supportLinkUIRegex = /\{\{supportLinkUI\}\}/gi;
    const supportLinkRegex = /\{\{supportLink\}\}/gi;
    const subscriptionLinkRegex = /\{\{subscriptionLink\}\}/gi;
    const customerPortalRegex = /\{\{customerPortal\}\}/gi;

    let { message, link } = model.errors[viewError || ERR_TYPES.GENERAL_ERROR]

    message = message.replaceAll(supportLinkUIRegex, process.env.REACT_APP_ENDEAVOROTC_SUPPORT_UI)
    message = message.replaceAll(supportLinkRegex, process.env.REACT_APP_ENDEAVOROTC_SUPPORT)
    message = message.replaceAll(subscriptionLinkRegex, priceSelectionURL)
    message = message.replaceAll(customerPortalRegex, process.env.REACT_APP_CUSTOMER_PORTAL)

    const tranferUser = () => {
      // FIX
      if (link && link.includes('try again')) {
        return createPaymentSession()
      }

      window.open(priceSelectionURL, '_self')
    }

    return (
      <div className={styles.error_container}>
        <LogoT01A />
        <div className={styles.error_message} dangerouslySetInnerHTML={{ __html: message }} />
        {link && (<PrimaryButton caption={link} onClick={tranferUser} />)}
      </div>
    )
  }

  useEffect(() => {
    if (!searchParams) {
      return setViewError(ERR_TYPES.UNPROCESSABLE_CONTENT)
    } 
    
    const price = searchParams.get('price_id')
    const coupon = searchParams.get('coupon_code')
    const instructions = searchParams.get('instructions') || ''

    if (!price) {
      return setViewError(ERR_TYPES.UNPROCESSABLE_CONTENT)
    }

    let decodedInstructions = decodeURIComponent(instructions)
    const cleanHtml = DOMPurify.sanitize(decodedInstructions)
    const cleanText = cleanHtml.replace(/\n/g, '')
    const instructionsLength = cleanText.length
    const instructionsNewLineCount = (decodedInstructions.match(/\\n/g) || []).length
    const instructionsBreakCount = (decodedInstructions.match(/<br/g) || []).length

    if (instructionsLength > 500 || (instructionsNewLineCount + instructionsBreakCount) > 10) {
      return setViewError(ERR_TYPES.UNPROCESSABLE_CONTENT)
    }

    setPrice(price)
    coupon && setCoupon(coupon)

    const aliasUuid = getAliasUuid(authToken)
    aliasUuid && setAliasUuid(aliasUuid)

    const userEmail = getEmail(authToken)
    userEmail && setEmail(userEmail)

    const subscriptionOptions = searchParams.get('subscription_options')?.split('?')[0] || process.env.REACT_APP_SUBSCRIPTION_LINK
    setPriceSelectionURL(subscriptionOptions)

    const hashedUUID = hashAliasUuid(aliasUuid)

    let successLink = searchParams.get('welcome')?.split('?')[0] || process.env.REACT_APP_PURCHASE_COMPLETION_URL
    successLink += `?price_id=${price}${coupon && '&coupon_code=' + coupon}${hashedUUID && '&orderid=' + hashedUUID}&subscription_options=${priceSelectionURL}`
    setPaymentSuccessURL(successLink)

    if (decodedInstructions) {
      decodedInstructions = DOMPurify.sanitize(decodedInstructions, {
        ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'u', 'br'],
        ALLOWED_ATTR: [],
      })
      setPaymentInstructions(decodedInstructions.split('\\n').join('<br />'))
    }
  }, [navigate, searchParams, tokenPending, authToken, priceSelectionURL])

  useEffect(() => {
    if (!viewLoading || !authToken || !priceId || !email || !aliasUuid || !paymentSuccessURL || !priceSelectionURL) { return }
    
    createPaymentSession()
    setViewLoading(false)
  }, [aliasUuid, authToken, createPaymentSession, email, paymentSuccessURL, priceId, priceSelectionURL, tokenPending, viewLoading])

  const hideInstructions = () => {
    setInstructionsVisible(false)
  }

  return (
    <>
      {checkoutId && (
        <>
          {paymentInstructions && instructionsVisible && <InstructionBanner message={paymentInstructions} handleOnCloseClick={hideInstructions}/>}
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              clientSecret: checkoutId,
              onComplete: handlePaymentComplete
            }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </>
      )}
      {!checkoutId && (
        <Layout hasFooter>
          <>
            {apiRequestInProgress && (<Loading />)}
            {!apiRequestInProgress && viewError && (
              <div>{renderRequestError()}</div>
            )}
          </>
        </Layout>
      )}
    </>
  )
}

export default Payment