import React, { FC, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuthToken } from '@utils'
import { Layout, Loading } from '@components'

 const Login: FC = () => {
  // const screenId = 'logout-screen'
  
  const { authToken, tokenPending } = useAuthToken()
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (!tokenPending && !authToken) {
      const redirectConfig = {
        authorizationParams: {
          initial_screen: 'login',
          app_name_id: 'T01A',
          social: '1'
        }
      }

      loginWithRedirect(redirectConfig)
    }
  }, [loginWithRedirect, authToken, tokenPending])

  return (
    <Layout hasFooter>
      <Loading />
    </Layout>
  )
}

export default Login
