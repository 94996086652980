declare let branch: any

class Branch {
  setIdentity = (id: string) => {
    if (branch) {
      branch.setIdentity(id)
    }
  }

  recordEvent = (
    name: 'LOGIN' | 'COMPLETE_REGISTRATION',
    segmentation: unknown = null
  ) => {
    branch.logEvent(name, segmentation)
  }
}

const branchInstance = new Branch()
export default branchInstance
