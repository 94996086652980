import React from 'react'

declare function cwr(operation: string, payload: any): void;
interface PropType {
  children: any
}

class ErrorBoundary extends React.Component<PropType> {
  componentDidCatch(error, errorInfo) {
    cwr && cwr('recordError', error)
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary
