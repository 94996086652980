import React, { FC, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuthToken } from '@utils'
import { Layout, Loading } from '@components'

 const Logout: FC = () => {
  // const screenId = 'logout-screen'
  
  const { logout } = useAuth0()
  const { authToken, tokenPending } = useAuthToken()

  useEffect(() => {
    if (!tokenPending) {
      logout({
        logoutParams: {
          returnTo: `${window.location.origin}/login`
        }
      })
    }
  }, [logout, tokenPending, authToken])

  return (
    <Layout hasFooter>
      <Loading />
    </Layout>
  )
}

export default Logout
