import React from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Layout, Loading } from '@components'

export const AuthenticationGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <Layout hasFooter>
          <Loading />
        </Layout>
      </div>
    ),
  })

  return <Component />
};